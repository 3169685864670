<template>
  <div class="c-video">
    <video-js
      ref="playerRef"
      class="video-js"
    />
    <div
      v-show="showPoster"
    >
      <slot v-if="!poster" />
      <img
        v-else
        :src="poster"
        alt="Video poster"
      >
    </div>
    <button
      v-if="showPlayBtnOverlay"
      class="c-video-play-button"
      @click="onHandleClick"
    >
      <IconPlay class="icon" />
    </button>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import {
  onBeforeUnmount, onMounted, ref, computed
} from 'vue'
import videojs from 'video.js'
import gtm from '@/helpers/gtm'
import IconPlay from '@/assets/svg/play-button--dark.svg'

export default {
  components: {
    IconPlay
  },
  props: {
    src: {
      type: String,
      required: true
    },
    poster: {
      type: String,
      default: ''
    },
    playsinline: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: true
    },
    showBtnPlay: {
      type: Boolean,
      default: true
    },
    heading: {
      type: String,
      default: 'Default'
    },
    slotContent: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    let player
    const playerRef = ref()
    const trackingDuration = []
    const availableTrackingDurations = [90, 75, 50, 25, 10]
    const showPlayBtnOverlay = ref(props.showBtnPlay)
    const showPoster = ref(props.showBtnPlay && !props.autoplay)
    const isPlaying = ref(false)
    const videoOptions = computed(() => {
      const {
        src,
        playsinline,
        autoplay,
        loop,
        muted,
        controls
      } = props

      return {
        playsinline,
        autoplay,
        loop,
        muted,
        controls,
        liveui: controls,
        sources: [
          {
            src,
            type: 'application/x-mpegURL'
          }
        ]
      }
    })

    const onHandleClick = () => {
      if (showPlayBtnOverlay.value) {
        player.play()
        showPlayBtnOverlay.value = false
        gtm.videoStart(props.heading)
      }
    }

    const getPercent = (tempPercent, percentValue) => {
      if (tempPercent > percentValue && !trackingDuration.includes(percentValue)) {
        return percentValue
      }
      return 0
    }

    onMounted(() => {
      player = videojs(playerRef.value, videoOptions.value)

      if (!props.autoplay) {
        player.on('ended', () => {
          gtm.videoComplete(props.heading)
        })
        player.on('play', () => {
          showPoster.value = false
        })
        player.on('timeupdate', () => {
          const tempPercent = Math.trunc((player.currentTime() * 100) / player.duration())
          let percent = 0
          for (let i = 0; i < availableTrackingDurations.length; i += 1) {
            percent = getPercent(tempPercent, availableTrackingDurations[i])
            if (percent !== 0) {
              break
            }
          }

          if (percent !== 0 && !trackingDuration.includes(percent)
            && availableTrackingDurations.includes(percent)) {
            gtm.videoProgress(props.heading, percent)
            trackingDuration.push(percent)
          }
        })
      }

      playerRef.value.addEventListener('slideChange', () => {
        player.pause()
      })
    })

    onBeforeUnmount(() => {
      if (player) {
        player.dispose()
      }
    })

    return {
      isPlaying,
      playerRef,
      showPlayBtnOverlay,
      showPoster,

      onHandleClick
    }
  }
}
</script>
